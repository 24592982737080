<template>
  <div class="tw-w-full tw-flex">
    <v-container style="max-width: 75rem" class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-8">
      <div style="width: 100%; align-items: center; justify-content: center; display: flex; margin-top: 2vh">
        <Logo/>
      </div>
      <div style="width: 100%; align-items: center; justify-content: center; display: flex; margin-top: 5vh">
        <v-card class="card" elevation="0" height="500">
          <p class="account-header">Login</p>
          <!--        <v-btn rounded elevation="0"-->
          <!--               class="mt-5" style="border: 1px solid #EAEAEA; color: #514A41; background-color: white; height: 42px">-->
          <!--          <img src="@/assets/google.svg" alt="google" class="mr-3"/> CONTINUE WITH GOOGLE-->
          <!--        </v-btn>-->
          <!--        <div class="mt-8" style="align-items: flex-start; display: flex; flex-direction: row">-->
          <!--          <v-divider/><p style="align-items: flex-start; margin-top: -1vh; margin-right: 1vh;-->
          <!--        margin-left: 1vh">OR</p><v-divider/>-->
          <!--        </div>-->
          <ValidationObserver v-slot="{ invalid, validate }" @submit.prevent="validate().then(handleLogin)">
            <div style="display: flex; flex-direction: column; width: 100%; height: auto;"
                 :style="[$vuetify.breakpoint.smAndDown ?{height:'15vh'}:{height: 'auto'}]">
              <label class="mb-2 label">Email</label>
              <ValidationProvider
                  name="email"
                  rules="required|min:8"
                  v-slot="{ errors }">
                <v-text-field
                    outlined
                    color="#FF7A01"
                    v-model="user.email"
                    height="44"
                    id="email"></v-text-field>
                <span class="err">{{ errors[0] ? 'Must be a valid email' : '' }}</span>
              </ValidationProvider>
            </div>
            <div class="mt-4" style="display: flex; flex-direction: column; width: 100%;height: auto;"
                 :style="[$vuetify.breakpoint.smAndDown ?{height:'15vh'}:{height: 'auto'}]">
              <label class="mb-2 label">Password</label>
              <ValidationProvider
                  name="password"
                  rules="required|min:8"
                  v-slot="{ errors }">
                <v-text-field
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'text' : 'password'"
                    outlined
                    v-model="user.password"
                    style="border-radius: 10px; border-color: #FF7A01"
                    height="44"
                    color="#FF7A01"
                    class="input"
                    id="password"></v-text-field>
                <span class="err">{{ errors[0] ? 'Password must be at least 8 characters' : '' }}</span>
              </ValidationProvider>
            </div>
            <div style="display: flex; width: 100%; justify-content: flex-end; height: auto; align-items: flex-start;">
              <v-btn rounded color="primary" text style="margin-bottom: 2vh; text-transform: none"
                     @click="forgotPasswordPage">Forgot Password?
              </v-btn>
            </div>
            <v-btn rounded block elevation="0" :disabled="invalid" color="#FF7A01" height="42" style="background-color: #FF7A01;
      color: white" @click="handleLogin" :loading="loading">LOGIN
            </v-btn>
          </ValidationObserver>
          <div
              style="display: flex; width: 100%; justify-content: center; height: auto; align-items: flex-start; margin-top: 2vh">
            <v-btn rounded block color="primary" text style="margin-bottom: 2vh; text-transform: none;"
                   @click="signupPage">No account? <span class="black--text">Sign up</span></v-btn>
          </div>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import Logo from "@/reuseable/Logo";
import {mapActions} from "vuex";

export default {
  name: "Login",
  components: {
    Logo

  },
  data() {
    return {
      value: '',
      user: {
        email: '',
        password: '',
      },
      loading: false
    }
  },
  methods: {
    ...mapActions('onboarding', ['userRegister']),
    forgotPasswordPage() {
      this.$router.push({name: 'ResetPassword'})
    },
    signupPage() {
      this.$router.push({name: 'Signup'})
    },
    handleLogin() {
      this.loading = true;
      this.$store.dispatch('onboarding/userLogin', this.user)
          .then(res => {
            this.loading = false
            if (this.$route.query.redirect) {
              this.$router.push({name: this.$route.query.redirect})
            } else {
              this.$router.push("/profile")

            }
          })
          .catch(err => {
            this.loading = false;
            this.$displaySnackbar({success: false, message: err.response.data.detail})
            console.log({err});

          });
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  display: flex;
  width: 450px;
  border: 1px solid #EAEAEA;
  border-radius: 32px;
  flex-direction: column;
  padding: 3% 3%;
  height: 87vh;

}

.account-header {
  color: #292929;
  font-weight: 700;
  font-size: 32px;
  text-align: center;

  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: center;

  }

}

.err {
  display: block;
  color: red;
  font-size: 13px;
  margin-bottom: 5px;
}
</style>
